import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function Alert (props) {
  let className = `ps-alert ${props.className}`
  if (props.isTransparent) className += ' ps-alert--transparent'
  else if (props.isNeutral) className += ' ps-alert--neutral'
  if (props.isSpecial) className += ' ps-alert--special'

  return (
    <div className={className}>
    {props.children}
  </div>
  )
}

Alert.propTypes = {
  isTransparent: PropTypes.any,
  isNeutral: PropTypes.any,
  isSpecial: PropTypes.any,
  onClick: PropTypes.any,
  disabled: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
}

function AlertWarning (props) {
  return (
    <Alert {...props} className="ps-alert--warning">
      {props.children}
    </Alert>
  )
}

AlertWarning.propTypes = {
  isTransparent: PropTypes.bool
}

function AlertSuccess (props) {
  return (
    <Alert {...props} className="ps-alert--success">
      {props.children}
    </Alert>
  )
}

AlertSuccess.propTypes = {
  isTransparent: PropTypes.any
}

function AlertDanger (props) {
  return (
    <Alert {...props} className="ps-alert--danger">
    {props.children}
  </Alert>
  )
}

AlertDanger.propTypes = {
  isTransparent: PropTypes.any
}

function AlertInfo (props) {
  return (
    <Alert {...props} className="ps-alert--info">
    {props.children}
  </Alert>
  )
}

AlertInfo.propTypes = {
  isTransparent: PropTypes.any
}

Alert.Warning = AlertWarning
Alert.Success = AlertSuccess
Alert.Danger = AlertDanger
Alert.Info = AlertInfo



export default Alert
