import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import swal from 'sweetalert'
import { Icon, Row } from 'antd'

import Signup from './signup'
import Signin from './signin'

import './style.scss'

class LoginModal extends React.Component {
  static propTypes = {
    isGenericLink: PropTypes.bool,
    client: PropTypes.any,
    hash: PropTypes.any,
    isModal: PropTypes.any
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      showSignup: false,
      isGenericLink: props.isGenericLink
    }

    if (props.client) {
      this.state.loginEmail = props.client.email
    }
  }

  componentDidMount() {
    if (this.props.isModal) {
      const { pathname } = window.location

      if (!pathname.includes('login')) {
        window.appHistory.push(`${pathname}/login`)
      }

      window.appHistory.listen(this.onRouteChange)
    }
  }

  onRouteChange() {
    const { pathname } = window.location
    if (pathname && !pathname.includes('login')) {
      swal.close()
    }
  }

  signup(b) {
    this.setState({ showSignup: !!b })
  }

  render() {
    return (
      <Row style={{ height: '100%' }}>
        {
          this.state.showSignup ?
            <Signup {...this.props} cancel={this.signup.bind(this, false)} /> :
            <Signin {...this.props} signup={this.signup.bind(this, true)} />
        }
      </Row>
    )
  }
}

LoginModal.show = async (hash, isGenericLink, client) => {
  let content = document.createElement('div')
  ReactDOM.render([
    <button key="modal-close" aria-label="Close" className="dialog-close" onClick={() => swal.close()}>
      <Icon type='close' />
    </button>,
    <LoginModal
      hash={hash}
      isGenericLink={isGenericLink}
      client={client}
      isModal
      key="login-modal"
    />
  ], content)

  return await swal({
    className: 'sel-login-modal',
    content,
    dangerMode: false,
    title: false,
    icon: false,
    buttons: false,
    closeOnClickOutside: false
  }).then(() => {
    const { pathname } = window.location
    if (pathname && pathname.includes('login')) {
      window.history.back()
    }
  })
}

export default LoginModal
