import ReactDOM from 'react-dom'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Icon from 'app/widgets/Icon'
import Button from 'app/widgets/Button'

import SelectionContext from '../../context'

import './style.scss'

class PreviewActions extends Component {
  static contextType = SelectionContext

  constructor (props, context) {
    super(props, context)

    this.setPictureState = this.setPictureState.bind(this)
  }

  setPictureState (selected, comment) {
    return this.context.setPictureState(this.context.highlightedPictureId, selected, comment)
  }

  render () {
    const pictureState = this.context.selected_pictures[this.context.highlightedPictureId]
    const hasComment = pictureState && pictureState.comment
    return (
      <div className={`sel__picture-preview__actions ${!!pictureState && 'selected'}`}>
        <button className="close" onClick={() => window.history.back()}>
          <Icon.ArrowBack />
        </button>
        {this.context.selection.enabled && (pictureState ? [
          <Button.Warning key={`h-${this.id}-unselect`} onClick={() => this.setPictureState(false)}>
            <Icon.Unselect /> <span>Desmarcar</span>
          </Button.Warning>,
          <Button isTransparent isSpecial={hasComment} key={`h-${this.id}-comment`} onClick={() => this.context.toggleCommentBox(this.context.highlightedPictureId)}>
            <Icon.Comment />
            {hasComment ? <span>Editar</span> : <span>Comentar</span>}
          </Button>
        ] : (
          <Button.Success onClick={() => this.setPictureState(true)}>
            <Icon.Select /> <span>Selecionar</span>
          </Button.Success>
        ))}
      </div>
    )
  }
}

PreviewActions.propTypes = {
  collection: PropTypes.any
}

export default PreviewActions
