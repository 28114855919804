import React, { Component } from 'react'
import PropTypes from 'prop-types'


import Icon from 'app/widgets/Icon'
import SelectionContext from '../../context'

import './style.scss'

class PreviewTools extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)
    this.myRef = React.createRef()
    this.setZoom = this.setZoom.bind(this)
  }

  setZoom(e) {
    this.props.zoomTo(e.scale)
  }

  render() {
    const pic = this.context.getPictureData(this.context.highlightedPictureId)
    const canSwap = this.props.canSwap
    return (
      <section className={`gal__picture-preview__tools ${canSwap && 'can-swap'}`} ref={this.myRef}>
        {canSwap && ([
          <button className="arrows prev" onClick={this.props.prev} key="goprev">
            <Icon.ArrowLeft />
          </button>,
          <button className="arrows next" onClick={this.props.next} key="gonext">
            <Icon.ArrowRight />
          </button>
        ])}
      </section>
    )
  }
}

PreviewTools.propTypes = {
  collection: PropTypes.any
}

export default PreviewTools
