import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

import Icon from 'app/widgets/Icon'
import Button from 'app/widgets/Button'

import SelectionContext from '../../context'

import './style.scss'

class PreviewActions extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)

    this.share = this.share.bind(this)
    this.download = this.download.bind(this)
  }

  share(target) {
    this.context.share(target, this.context.highlightedPictureId, 'menu-2')
  }

  download() {
    this.context.downloadPicture(this.context.highlightedPictureId)
  }

  render() {
    const pic = this.context.getPictureData(this.context.highlightedPictureId)
    return (
      <div className={`gal__picture-preview__actions`}>
        <button className="close" onClick={() => window.history.back()}>
          <Icon.Back />
        </button>
        <section className="info">
          <span>
            {(+pic.index) + 1} de {this.props.picturesCount}
          </span>
        </section>
        <div className='action-btns'>
          {this.context.gallery.single_download &&
            <Tooltip placement='bottomLeft' title={`${pic.downloading ? 'Preparando' : 'Faça'} download da foto`}>
              <Button.Success isNeutral onClick={() => this.download()} disabled={pic.downloading}>
                {
                  pic.downloading ?
                    <i className='fa fa-spinner fa-pulse' /> :
                    <i className='fa fa-download' />
                }
              </Button.Success>
            </Tooltip>
          }
          {this.context.gallery.allow_share &&
            <Tooltip placement='bottomLeft' title='Compartilhar foto'>
              <Button.Success isNeutral onClick={e => this.share(e.currentTarget)}>
                <i className='fa fa-share' />
              </Button.Success>
            </Tooltip>
          }
        </div>
      </div>
    )
  }
}

PreviewActions.propTypes = {
  collection: PropTypes.any
}

export default PreviewActions
