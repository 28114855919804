import React from 'react'
import ReactDOM from 'react-dom'
import swal from 'sweetalert'

import SendWhatsapp from './SendWhatsapp'

import './ShareDialogs.scss'

export default {
    openWhatsapp(props = {}) {
        const content = document.createElement('div')
        ReactDOM.render(<SendWhatsapp link={props.href} />, content)
        return swal({
            className: 'share-send-dialog',
            content,
            buttons: false,
            closeOnClickOutside: false
        })
    }
}
