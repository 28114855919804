const API_URL = process.env.API_URL
const GALLERY_API_URL = process.env.GALLERY_API_URL

const API = {
  async downloadSinglePicture(picture_id, collection_id) {
    try {
      const pictureUrl = `${GALLERY_API_URL}/pictures/download?picture_id=${picture_id}&collection_id=${collection_id}`

      // Verify if can download picture
      const res = await fetch(pictureUrl, {
        method: "HEAD",
        credentials: 'include'
      })

      // Downloads picture if it is able to
      if (res.status === 200) {
        window.open(pictureUrl, "_self")
      }
    } catch (error) {
      console.log(error)
      return { error }
    }
  },
  frontDataFPBlockedError: "FPBlocked",
  async getFrontData(hashid) {
    try {
      const res = await fetch(`${GALLERY_API_URL}/home/front/data?hash=${hashid}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async logoutClientFromGalleryPro() {
    try {
      const res = await fetch(`${GALLERY_API_URL}/home/logout`, { credentials: 'include' })
      return res
    } catch (error) {
      return { error }
    }
  },
  async getSelection(hashid) {
    try {
      const res = await fetch(`${API_URL}/select/selection/${hashid}`, { credentials: 'include',  })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async getGallery(hashid) {
    try {
      const res = await fetch(`${API_URL}/select/gallery/${hashid}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async getGalleryPicture(hashid) {
    try {
      const res = await fetch(`${API_URL}/select/gallery/picture/${hashid}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async pushAccessLog({ hash, service, isPWA }) {
    try {
      const res = await fetch(`${API_URL}/${service}/log/access`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ hash, service, isPWA })
      })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async prepareGalleryPicture(hashid, id) {
    try {
      const res = await fetch(`${API_URL}/gallery/picture/${hashid}/${id}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async prepareGalleryZIP(hashid, data) {
    try {
      const res = await fetch(`${API_URL}/gallery/zip/${hashid}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data || {})
      })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async getSelectionZIP(hashid) {
    try {
      const res = await fetch(`${API_URL}/selection/zip/${hashid}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async postSelectionZIP(hashid, data) {
    try {
      const res = await fetch(`${API_URL}/selection/zip/${hashid}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data || {})
      })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  async getPicture(hashid, id) {
    try {
      const res = await fetch(`${API_URL}/selection/picture/${hashid}/${id}`, { credentials: 'include' })
      return res.json()
    } catch (error) {
      return { error }
    }
  },
  makeDownloadPictureUrl(path) {
    if (process.env.NODE_ENV === 'development') {
      return `${process.env.PUBLIC_TMP}/single-download/${path}`
    }
    return `https://single-download.s2.picsize.com.br/${path}`
  },
  async downloadPicture(path) {
    try {
      const res = await fetch(this.makeDownloadPictureUrl(path), { method: 'GET' })
      return res.blob()
    } catch (error) {
      return { error }
    }
  },
  async download(url) {
    try {
      const res = await fetch(url, { method: 'GET' })
      return res.blob()
    } catch (error) {
      return { error }
    }
  },
  async postSelection(hashid, selectionData) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ selectionData })
      })

      return rawResponse.json()
    } catch (error) {
      return {
        error
      }
    }
  },
  async checkoutSelection(hashid, selectionData) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/checkout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ selectionData })
      })

      return rawResponse.json()
    } catch (error) {
      return {
        error
      }
    }
  },
  async postSignup(hashid, data) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/signup`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  },
  async postLogin(hashid, data) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  },
  async postLogout() {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  },
  async postRecover(hashid, data) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/recover`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  },
  async postPasswordReset(data) {
    const { hashid, token } = data
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/reset-password/${token}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  },
  async getResetTokenData(hashid, token) {
    try {
      const rawResponse = await fetch(`${API_URL}/select/selection/${hashid}/reset-password/${token}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })

      return rawResponse.json()
    } catch (error) {
      return { error }
    }
  }
}
export default API
