import React, {Component} from 'react'
import PropTypes from 'prop-types'

import SummaryPictureList from './PictureList'

import SelectionContext from '../context'

import './style.scss'

class SelectionSummary extends Component {
  static contextType = SelectionContext
  static propTypes = {
    picturesCount: PropTypes.any
  }

  constructor(props, context) {
    super(props, context)
  }

  async componentDidMount () {
  }

  render () {
    return (
      <div className="sel__summary">
        <SummaryPictureList />
      </div>
    )
  }
}

export default SelectionSummary
