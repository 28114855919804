import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Form, Button, Input, Icon} from 'antd'


import API from '~/modules/API'

import './style.scss'

class Support extends Component {
  static propTypes = {
    params: PropTypes.object
  }

  constructor(props, context) {
    super(props, context)

    const {hashid, reset_token} = this.props.params

    this.state = {
      hashid,
      token: reset_token,

      isLoading: false,
      isSubmiting: false,
      isValidHash: false,

      newPassword: '',
      newPasswordConfirm: ''
    }
  }

  loadData = async () => {
    this.setState({loading: true})
    const data = await API.getResetTokenData(this.state.hashid, this.state.token)
    const {id, customer_id, email} = data

    if (!data || !data.success || !id || !customer_id || !email) {
      if (this.state.hashid) {
        window.location = `/${this.state.hashid}/select`
      }
      return this.setState({loading: false, error: true})
    }

    this.setState({loading: false, id, customer_id, email, isValidHash: true})
  }

  postData = async (e) => {
    e.preventDefault()

    this.setState({loading: true, isSubmiting: true})
    const data = await API.postPasswordReset(this.state)
    this.setState({loading: false, isSubmiting: false})

    if (!data || !data.success) {
      return alert('Não foi possível alterar a senha!')
    }

    alert('Senha alterada com sucesso!')
    return window.location.reload()
  }

  onChange = (value, key) => {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    const newState = {[key]: value}

    this.setState(newState)
  }

  get isValidPasswordSet () {
    const {newPassword, newPasswordConfirm} = this.state
    return newPassword && newPassword.length && newPassword === newPasswordConfirm
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    if (!this.state.isValidHash) return null

    return (
      <div className="recover-page">
        <Form layout="vertical" onSubmit={this.postData}>
          <Form.Item label="E-mail">
            <Input
              name="loginEmail"
              value={this.state.email}
              readOnly
              disabled
              prefix={(
                <Icon type="mail" style={{color: 'rgba(0,0,0,.25)'}} />
              )}
            />
          </Form.Item>
          <Form.Item label="Nova senha">
            <Input.Password
              name="newPassword"
              value={this.state.newPassword}
              onChange={this.onChange}
              prefix={(
                <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />
              )}
            />
          </Form.Item>
          <Form.Item label="Confirmação de senha">
            <Input.Password
              name="newPasswordConfirm"
              value={this.state.newPasswordConfirm}
              onChange={this.onChange}
              prefix={(
                <Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}} />
              )}
            />
          </Form.Item>
          <Button htmlType="submit" loading={this.state.isSubmiting} disabled={!this.isValidPasswordSet}>
            Alterar senha
          </Button>
        </Form>
      </div>
    )
  }
}

export default Support
