import React from 'react'
import Loading from 'app/widgets/Loading'
import PinchZoom from './PinchZoom'
import {Tooltip} from 'antd'
import CustomDownloadName from '~/widgets/CustomDownloadName'

// 3 colunas
// pinch zoom desativa passagem de fotos
// apenas selecionads

import './style.scss'

class PreviewImage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: true
    }
  }

  componentDidMount () {
    this.pz = new PinchZoom(this.props.imgRef.current, this.props.containerRef.current, {
      use2d: false,
      zoomOutFactor: 0.8,
      minZoom: 0.8,
      draggableUnzoomed: false
    })
    this.props.imgRef.current.pz = this.pz
    this.props.imgRef.current.parentElement.parentElement.classList.add('loading') // parent.parent is containerRef
    this.props.imgRef.current.addEventListener('pz_zoomupdate', () => {
      this.props.onZoomUpdate()
    })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.picture.id !== this.props.picture.id) {
      const isComplete = this.props.imgRef.current && this.props.imgRef.current.complete
      this.setState({isLoading: !isComplete}, () => {
        if (this.state.isLoading) {
          this.props.containerRef.current.classList.remove('loaded')
          this.props.containerRef.current.classList.add('loading')
          return
        }
        this.props.containerRef.current.classList.remove('loading')
        if (this.props.picture.index < prevProps.picture.index) {
          this.props.containerRef.current.classList.add('slideInLeft')
        } else {
          this.props.containerRef.current.classList.add('slideInRight')
        }
        this.props.containerRef.current.classList.add('loaded')
        setTimeout(() => {
          this.props.containerRef.current.classList.remove('slideInLeft')
          this.props.containerRef.current.classList.remove('slideInRight')
        }, 400)
      })
      // console.log('isComplete', isComplete)
      // this.setState({isLoading: !isComplete}, () => {
      //   this.props.imgRef.current.parentElement.classList.remove('loading')
      //   this.props.imgRef.current.parentElement.classList.add('loaded')
      // })
    }
    // this.props.imgRef.current.style.opacity = 0
  }

  handleImageLoaded () {
    // setTimeout(() => {
    // }, 1500)
    this.setState({isLoading: false}, () => {
      this.props.containerRef.current.classList.remove('loading')
      this.props.containerRef.current.classList.add('loaded')
    })
  }

  handleImageErrored () {
    this.setState({isLoading: false})
  }

  render() {
    return (
      <div className="sel__picture-preview__image" ref={this.props.containerRef}>
        <img src={this.props.prevSrc} className="sel__picture-preview__image__prev" />
        <CustomDownloadName name={this.props.picture.title}>
          <img
            onClick={this.props.onClick}
            src={this.props.picture.image}
            ref={this.props.imgRef}
            onLoad={this.handleImageLoaded.bind(this)}
            onError={this.handleImageErrored.bind(this)} />
        </CustomDownloadName>
        <img src={this.props.nextSrc} className="sel__picture-preview__image__next" />
      {this.props.picture.suggested &&
        <div className='suggested-flag'>
          <Tooltip placement='leftBottom' title='Foto sugerida pelo fotógrafo'>
            <i className='fa fa-star'></i>
          </Tooltip>
        </div>
      }
      {this.state.isLoading && <div><Loading /></div>}
    </div>
    )
  }
}

export default PreviewImage
