import React, {Component} from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert'

import ListItem from './Item'

import SelectionContext from '../../context'

import './style.scss'

class SelectionSummaryPictureList extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)
  }

  async unselect (picId) {
    const willUnselect = await swal({
      text: 'Desmarcar foto?',
      icon: false,
      buttons: {
        cancel: 'Cancelar',
        confirm: 'Sim'
      }
    })
    if (!willUnselect) return false
    this.context.setPictureState(picId, false)
  }

  render () {
    if (!Object.keys(this.context.selected_pictures).length) return false
    return (
      <div className="sel__summary__picture-list">
        <h3>Fotos selecionadas</h3>
        {this.context.getSelectedPictures().map((picId) => {
          const pic = this.context.getPictureData(picId)
          return (
            <ListItem
              key={`summary-pic-${pic.id}`}
              picture={pic}
              editComment={() => this.context.toggleCommentBox(pic.id)}
              maximize={() => this.context.previewToggle(pic.id)}
              unselect={() => this.unselect.bind(this)(pic.id)}
              pictureComment={pic.comment}
            />
          )
        })}
      </div>
    )
  }
}

SelectionSummaryPictureList.propTypes = {
  collection: PropTypes.any
}

export default SelectionSummaryPictureList
