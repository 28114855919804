import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function Button (props) {
  let className = `ps-button ${props.className}`
  if (props.isTransparent) className += ' ps-button--transparent'
  else if (props.isNeutral) className += ' ps-button--neutral'
  if (props.isSpecial) className += ' ps-button--special'

  return (
    <button onClick={props.onClick} className={className} disabled={props.disabled} tabIndex={0}>
    {props.children}
    </button>
  )
}

Button.propTypes = {
  isTransparent: PropTypes.any,
  isNeutral: PropTypes.any,
  isSpecial: PropTypes.any,
  onClick: PropTypes.any,
  disabled: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
}

function ButtonWarning (props) {
  return (
    <Button {...props} className="ps-button--warning">
      {props.children}
    </Button>
  )
}

ButtonWarning.propTypes = {
  isTransparent: PropTypes.bool
}

function ButtonSuccess (props) {
  return (
    <Button {...props} className="ps-button--success">
      {props.children}
    </Button>
  )
}

ButtonSuccess.propTypes = {
  isTransparent: PropTypes.any
}

function ButtonDanger (props) {
  return (
    <Button {...props} className="ps-button--danger">
    {props.children}
    </Button>
  )
}

ButtonDanger.propTypes = {
  isTransparent: PropTypes.any
}

function ButtonInfo (props) {
  return (
    <Button {...props} className="ps-button--info">
    {props.children}
    </Button>
  )
}

ButtonInfo.propTypes = {
  isTransparent: PropTypes.any
}

Button.Warning = ButtonWarning
Button.Success = ButtonSuccess
Button.Danger = ButtonDanger
Button.Info = ButtonInfo



export default Button
