import React from 'react'
import PropTypes from 'prop-types'

import './style.scss'

function Table (props) {
  return (
    <div className="ps-info-table">
    {props.children}
    </div>
  )
}

function TableItem (props) {
  let className = 'ps-info-table__item'

  if (props.isInfo) {
    className += ' ps-info-table__item--info'
  }

  return (
    <div className={className}>
      {!props.isInfo && <section>{props.name}</section>}
      <section>{props.value}</section>
    </div>
  )
}

Table.propTypes = {
  isTransparent: PropTypes.any,
  isNeutral: PropTypes.any,
  isSpecial: PropTypes.any,
  onClick: PropTypes.any,
  disabled: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
}

Table.Item = TableItem

export default Table
