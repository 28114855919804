import React, { Component } from 'react'
import ReactDom from 'react-dom'

import Utils from '~/modules/Utils'
import './FullDialogs.scss'

export default {
    open(dialog, opts) {
        if (!dialog) {
            return
        }
        opts = opts || {}
        opts.method = opts.method || 'render'

        const defer = Utils.promiseDefer()
        const id = 'sl-full-dialog-portal'
        let host = document.getElementById(id)

        if (host && typeof host.close === 'function') {
            host.close()
        }

        host = document.createElement('div')
        host.id = id
        host.className = opts.className || ''
        host.close = close
        document.body.appendChild(host)

        let component

        if (opts.method === 'portal') {
            component = ReactDom.createPortal(React.cloneElement(dialog, { close, fancyClose }), host)
        }
        else {
            component = ReactDom.render(React.cloneElement(dialog, { close, fancyClose }), host)
        }


        function close() {
            if (opts.method === 'render') {
                ReactDom.unmountComponentAtNode(host)
            }
            host.remove()
            defer.resolve()
        }

        function fancyClose() {
            host.classList.add('--closing')
            setTimeout(() => close(), 6e2)
        }

        return { close, fancyClose, onClose: defer.promise, component }
    }
}
