import 'intersection-observer'
import React, { Component } from 'react'
import API from 'app/modules/API'
import PropTypes from 'prop-types'

import GalleryItem from './Item'
import SelectionContext from '../context'

import './style.scss'

class SelectionGallery extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)

    this.state = {
      mountedPictures: []
    }

    this.handleObserverUpdate = this.handleObserverUpdate.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.incrementMountedPictures = this.incrementMountedPictures.bind(this)
    this.downloadPicture = this.downloadPicture.bind(this)
    this.observer = new IntersectionObserver(this.handleObserverUpdate, {
      rootMargin: `${window.screen.availHeight * 1.5}px 0px`
    })
  }

  componentDidMount() {
    this.context.updateVisiblePicturesArray()
    window.addEventListener('scroll', this.handleScroll, false)
    this.incrementMountedPictures()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, false)
    this.observer.disconnect()
  }

  handleScroll(e) {
    if ((window.innerHeight + window.scrollY) < (document.body.offsetHeight - window.screen.availHeight)) return
    this.incrementMountedPictures()
  }

  incrementMountedPictures() {
    this.setState({
      mountedPictures: this.props.visiblePictures.slice(0, this.state.mountedPictures.length + 25)
    })
  }

  // shouldComponentUpdate () {
  //   return true
  // }

  handleObserverUpdate(e) {
    for (const entry of e) {
      const { top, left, bottom, right } = entry.intersectionRect
      if ([top, bottom, left, right].some(Boolean)) {
        entry.target.parentElement.classList.add('sel__gallery__item--visible')
        entry.target.attributes['data-is-visible'].value = '1'
        entry.target.src = entry.target.attributes['data-thumb-src'].value
      } else {
        entry.target.parentElement.classList.remove('sel__gallery__item--visible')
        entry.target.attributes['data-is-visible'].value = '0'
        entry.target.src = entry.target.attributes['data-mini-src'].value
      }
    }
  }

  async downloadPicture(i) {
    const res = await API.getPicture(this.context.hashid, i.id)
    if (!res || !res.ready || typeof res.filePath !== 'string') {
      return false
    }
    const b = await API.downloadPicture(res.filePath)
    const url = URL.createObjectURL(b)
    const a = document.createElement('a')
    a.href = url
    if (res.picture && res.picture.title) {
      a.download = res.picture.title
    }
    else {
      a.download = filePath.split('/').pop()
    }
    a.dispatchEvent(new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': false
    }))
    return true
  }

  render() {
    return (
      <div className="sel__gallery">
        {this.props.visiblePictures.filter((picId) => this.state.mountedPictures.includes(picId)).map((picId) => {
          const pic = this.context.getPictureData(picId)
          return (
            <GalleryItem
              key={`gallery-pic-${picId}-index`}
              id={`g-pic-${picId}`}
              picture={pic}
              selectionEnabled={this.props.selectionEnabled}
              isSelected={pic.selected}
              isCommented={(!!pic.comment && !!pic.comment.length)}
              setPictureState={(selected) => this.props.setPictureState(picId, selected)}
              toggleCommentBox={() => this.props.toggleCommentBox(picId)}
              openPicturePreview={() => this.props.previewToggle(picId)}
              observer={this.observer}
              download={this.downloadPicture}
              allowCopy={this.props.allowCopy}
              collectionType={this.props.collectionType}
            />
          )
        })}
      </div>
    )
  }
}

SelectionGallery.propTypes = {
  collection: PropTypes.any,
  visiblePictures: PropTypes.array,
  selectionEnabled: PropTypes.any,
  allowCopy: PropTypes.bool,
  collectionType: PropTypes.any
}

export default SelectionGallery
