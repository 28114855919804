/* eslint-disable max-statements */
import React, { Component } from 'react'

import GalleryContext from '../GalleryContext'
import FullDialogs from './FullDialogs'

import './About.scss'

export default {
    open(props = {}, isMobile) {
        return FullDialogs.open(
            <GalleryAboutPage {...props} />,
            { className: `sf-gallery-about-dialog${isMobile ? ' mobile-gallery' : ''}`, method: 'portal' }
        )
    }
}

class GalleryAboutPage extends Component {
    static contextType = GalleryContext

    constructor(props, context) {
        super(props, context)

        if (!context || !context.customer) {
            this.state = { customer: false }
            return
        }

        this.state = {
            isMobile: context.isMobile,
            customer: context.customer,
            customerLogo: context.customer_logo,
            displayName: context.customer.displayName,
            displayEmail: context.customer.displayEmail,
            phoneType: context.customer_phoneType || { phone_type: 'telefone' },
            telephone: context.customer.telephone,
            bio: context.customer.bio,
            medias: this.parseMedias(context.customer.socialMedias)
        }
    }

    parseMedias(medias) {
        const customer = this.context.customer
        let phone_type = ''
        if (!this.context.customer_phoneType) {
          phone_type = 'telefone'
        } else {
          phone_type = this.context.customer_phoneType.phone_type
        }
        const mobile = this.context.isMobile
        const arr = [
            {
                icon: 'fa fa-envelope-o',
                text: customer.displayEmail,
                href: `mailto:${customer.displayEmail}`,
                target: '_top'
            }
        ]
        let icon = 'fa fa-phone'
        let href = `tel:${customer.telephone}`
        if (phone_type === 'telefone') {
            icon = 'fa fa-phone'
            href = `tel:${customer.telephone}`
        } else if (phone_type === 'whatsapp') {
            icon = 'fa fa-whatsapp'
            if (mobile) {
                href = `https://api.whatsapp.com/send?phone=55${customer.telephone.replace(/\W|^(55)/g, '')}`
            }  else {
                href = `https://web.whatsapp.com/send?phone=55${customer.telephone.replace(/\W|^(55)/g, '')}`
            }
        }
        if (phone_type === 'telefone-whatsapp') {
            icon = [
                'fa fa-phone',
                'fa fa-whatsapp'
            ]
            if (mobile) {
                href = [
                    `tel:${customer.telephone}`,
                    `https://api.whatsapp.com/send?phone=55${customer.telephone.replace(/\W|^(55)/g, '')}`
                ]
            }  else {
                href = [
                    `tel:${customer.telephone}`,
                    `https://web.whatsapp.com/send?phone=55${customer.telephone.replace(/\W|^(55)/g, '')}`
                ]
            }
            if (customer.telephone) {
                arr.push({
                    icon: icon[0],
                    text: customer.telephone,
                    href: href[0],
                    target: '_top'
                })
                arr.push({
                    icon: icon[1],
                    text: customer.telephone,
                    href: href[1],
                    target: '_top'
                })
            }
        } else {
            arr.push({
                icon,
                text: customer.telephone,
                href,
                target: '_top'
            })
        }
        if (!medias) {
            return arr
        }
        const icons = {
            site: 'fa fa-globe',
            facebook: 'fa fa-facebook',
            instagram: 'fa fa-instagram',
            twitter: 'fa fa-twitter'
        }

        if (medias["site"]) {
            arr.push({ type: "site", icon: icons["site"], ...this.parseHref("site", medias) })
        }

        if (medias["instagram"]) {
            arr.push({ type: "instagram", icon: icons["instagram"], ...this.parseHref("instagram", medias) })
        }

        if (medias["facebook"]) {
            arr.push({ type: "facebook", icon: icons["facebook"], ...this.parseHref("facebook", medias) })
        }

        if (medias["twitter"]) {
            arr.push({ type: "twitter", icon: icons["twitter"], ...this.parseHref("twitter", medias) })
        }

        return arr
    }

    parseHref(k, medias) {
        const prefixes = {
            site: '',
            facebook: 'https://www.facebook.com/',
            instagram: 'https://www.instagram.com/',
            twitter: 'https://twitter.com/'
        }
        const pattern = {
            facebook: 'facebook.com/',
            instagram: 'instagram.com/',
            twitter: 'twitter.com/',
        }
        const v = medias[k]
        if (k === 'facebook') {
            if (v.indexOf('facebook.com/') !== -1) {
                return { text: v.split('facebook.com/').pop(), href: v }
            }
            else {
                return { text: v, href: `${prefixes[k]}${v}` }
            }
        }
        if (pattern[k] && v.indexOf(pattern[k]) !== -1) {
            return { text: v.split(pattern[k]).pop(), href: v }
        }
        return { text: v, href: `${prefixes[k]}${v}` }
    }

    render() {
        if (!this.state.customer) {
            return null
        }
        return (
            <div className='sf-gallery-about'>

                <div className='about-card'>
                    {this.state.customerLogo
                        ? (<img src={this.state.customerLogo} alt="Logomarca do fotógrafo" height="74" width="auto" style={{marginBottom: '20px'}} />)
                        : null
                    }
                    <div className='card-title'>{this.state.displayName}</div>
                    <p className='card-text'>
                        {this.state.bio}
                    </p>
                    <ul className='card-list'>
                        {this.state.medias.map((i, id) => this.renderListItem(i, id, i.target))}
                    </ul>
                </div>

            </div>
        )
    }

    renderListItem({ icon, text, href }, id, target = 'blank') {
        return (
            <li className='list-item' key={`sf-about-${id}`}>
                <a target={target} href={href}>
                    <i className={icon}></i>
                    <span>{text}</span>
                </a>
            </li>
        )
    }

}
