import ReactDOM from 'react-dom'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Button from 'app/widgets/Button'
import Icon from 'app/widgets/Icon'
import SelectionContext from '../../context'

import './style.scss'

class SelectionCommentArea extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)

    this.id = this.context.highlightedPictureId

    const picState = this.context.selected_pictures[this.id]
    this.state = {
      comment: (picState && picState.comment) || ''
    }
  }

  saveComment () {
    this.context.setPictureState(this.id, true, this.state.comment)
    this.context.toggleCommentBox()
  }

  onTextChange (e) {
    this.setState({comment: e.target.value})
  }

  render() {
    return (
      <div className="sel__picture-preview__comment-area">
        <section>
          <header>
          <Icon.Comment /> Comentar foto selecionada
          </header>
          <div>
            <textarea value={this.state.comment} onChange={this.onTextChange.bind(this)} />
          </div>
          <footer>
            <Button.Danger isTransparent onClick={() => this.context.toggleCommentBox()}>
              <span>Cancelar</span>
            </Button.Danger>
            <Button.Info onClick={this.saveComment.bind(this)}>
              <span>Salvar comentário</span>
            </Button.Info>
          </footer>
        </section>
      </div>
    )
  }
}

SelectionCommentArea.propTypes = {
  collection: PropTypes.any
}

export default SelectionCommentArea
