import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Input,
  Button,
  Form,
  Alert,
  Divider,
  Icon,
} from 'antd'

import API from '~/modules/API'

// eslint-disable-next-line no-useless-escape
const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export default class Signin extends Component {
  static propTypes = {
    isGenericLink: PropTypes.bool,
    client: PropTypes.any,
    hash: PropTypes.any,
    isModal: PropTypes.any,
    signup: PropTypes.func
  }

  constructor(props, context) {
    super(props, context)
    this.state = {
      isSubmitingLogin: false,
      isSubmitingRecover: false,
      loginEmail: '',
      loginPassword: '',
      valid: false,
      loginValidation: false,
      isGenericLink: props.isGenericLink
    }

    if (props.client) {
      this.state.loginEmail = props.client.email
    }

    this.onChange = this.onChange.bind(this)
    this.setActionValidation = this.setActionValidation.bind(this)
  }

  onChange(value, key) {
    if (!key) {
      key = value.target.name
      value = value.target.value
    }

    if (key === 'loginEmail') {
      value = (value || '').toLowerCase()
    }

    const newState = { [key]: value }

    this.setState(newState, () => {
      const { loginEmail: email, loginPassword: password } = this.state
      const valid = typeof email === 'string' && emailPattern.test(email) &&
        typeof password === 'string' && /^.{3,32}$/.test(password)
      this.setState({ valid })
    })
  }

  // section = 'login' or 'signup'
  setActionValidation(section, validationData) {
    this.setState({
      loginValidation: false,
      signupValidation: false,
      isSubmitingRecover: false,
      isSubmitingLogin: false,
      isSubmitingSignup: false,
      [`${section}Validation`]: { ...validationData }
    })
  }

  async submitLogin(event) {
    event.preventDefault()
    this.setState({ isSubmitingLogin: true })

    const result = await API.postLogin(this.props.hash, {
      email: this.state.loginEmail,
      password: this.state.loginPassword
    })

    if (!result.error) {
      window.location = `/${result.hash}/select`
      return
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Email inválido!'
        }
      })
    }

    if (result.error === 'INVALID_PASSWORD') {
      return this.setActionValidation('login', {
        password: {
          validateStatus: 'error',
          help: 'Senha incorreta!'
        }
      })
    }
  }

  async recoverPassword(event) {
    event.preventDefault()

    if (!this.state.loginEmail && !this.state.loginEmail.length) {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Informe o email cadastrado para recuperar a senha.'
        }
      })
    }

    this.setState({ isSubmitingRecover: true })

    const result = await API.postRecover(this.props.hash, {
      email: this.state.loginEmail
    })

    if (!result.error) {
      return this.setActionValidation('login', {
        alert: {
          message: 'Um link de recuperação de senha foi enviado ao seu email!',
          type: 'success',
          showIcon: true
        },
        email: {
          validateStatus: 'success'
        }
      })
    }

    if (result.error === 'INVALID_EMAIL') {
      return this.setActionValidation('login', {
        email: {
          validateStatus: 'error',
          help: 'Email não cadastrado'
        }
      })
    }

    if (result.error === 'EXCEPTION') {
      return this.setActionValidation('login', {
        alert: {
          message: 'Não foi possível recuperar a senha!',
          type: 'error',
          showIcon: true
        }
      })
    }
  }

  render() {
    return (
      <>
        <div className="dialog-header">
          <div className="dialog-title" id="rcDialogTitle0">
            Entrar
          </div>
        </div>
        <div className='dialog-body' style={{ paddingBottom: '32px' }}>
          <LoginForm
            values={this.state}
            onChange={this.onChange}
            onSubmit={this.submitLogin.bind(this)}
            recoverPassword={this.recoverPassword.bind(this)}
          />
          {
            this.state.isGenericLink &&
            <>
              <Divider orientation='center'>Ou</Divider>
              <Form.Item key="buttons">
                <Button type="default" block onClick={this.props.signup}>Criar Conta</Button>
              </Form.Item>
            </>
          }
        </div>
      </>
    )
  }

}

function LoginForm({ values, onChange, onSubmit, recoverPassword }) {
  const loginValidation = !!values.loginValidation && values.loginValidation
  const valid = !!values.valid
  return (
    <Form layout="vertical" onSubmit={onSubmit}>
      {!!loginValidation.alert && (<Alert {...loginValidation.alert} />)}
      <Form.Item label="E-mail" key="loginEmail" {...(loginValidation && loginValidation.email)}>
        <Input
          name="loginEmail"
          value={values.loginEmail}
          onChange={onChange}
          disabled={!values.isGenericLink}
          autoCapitalize='off'
          style={{ textTransform: 'lowercase' }}
          prefix={(
            <Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />
          )}
        />
      </Form.Item>
      <Form.Item label="Senha" key="loginPassword" {...(loginValidation && loginValidation.password)}>
        <Input
          name="loginPassword"
          value={values.loginPassword}
          onChange={onChange}
          type="password"
          disabled={values.isSubmitingRecover}
          prefix={(
            <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
          )}
        />
      </Form.Item>
      <Form.Item key="recover-button">
        <Button type="link" style={{ float: 'right' }}
          onClick={recoverPassword} loading={values.isSubmitingRecover}>
          <>Esqueci a senha</>
        </Button>
      </Form.Item>
      <Form.Item key="submit-button">
        <Button
          type="primary"
          htmlType="submit"
          block size='large'
          loading={values.isSubmitingLogin}
          disabled={values.isSubmitingRecover || !valid}
        >
          <>Entrar</>
        </Button>
      </Form.Item>
    </Form>
  )
}

LoginForm.propTypes = {
  values: PropTypes.any,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  recoverPassword: PropTypes.func
}
