import React, { Component } from 'react'
import { Link, scroller } from "react-scroll"

import API from "~/modules/API"

import DownloadDialogs from '../Dialogs/DownloadDialogs'
import { GalleryAboutPage } from "../FullDialogs/NewAboutModal"
import ShareMenuModal from "../Dialogs/ShareMenuModal"

import IconButton from "@material-ui/core/IconButton"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import Modal from "@material-ui/core/Modal"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import CloudDownloadIcon from "@material-ui/icons/CloudDownload"
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined"
import CancelIcon from "@material-ui/icons/Cancel"

import SelectionContext from '../context'

import './style.scss'

class SelectionHeader extends Component {
  static contextType = SelectionContext
  shouldUnload = false
  headerWrap;

  constructor(props, context) {
    super(props, context)
    this.state = {
      coverUrl: '',
      hasCover: false,
      coverReady: false,
      blur: '',
      menuAnchorElement: null,
      aboutModalVisible: false,
      avatarVisible: true
    }
    this.unloadCover = this.unloadCover.bind(this)
    this.loadCover = this.loadCover.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.downloadAllPictures = this.downloadAllPictures.bind(this)
  }

  componentDidMount() {
    this.headerWrap = document.getElementById('header-wrap')
    window.addEventListener('scroll', this.onScroll, true)
    this.loadCover()
    if (this.props.isCheckoutPage) {
      setTimeout(() => scroller.scrollTo('page-content', { offset: -45 }), 1e2)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  onScroll() {
    if (window.scrollY > this.headerWrap.clientHeight) {
      if (!this.shouldUnload) {
        this.unloadCover();
        this.shouldUnload = true
      }
    }
    else {
      if (this.shouldUnload) {
        this.loadCover();
        this.shouldUnload = false
      }
    }
  }

  unloadCover() {
    const { coverKey, cover } = this.context

    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    this.setCover(`https://img.picsize.com.br/thumb/max/32/${coverPath}`)
  }

  loadCover() {
    const { coverKey, cover, collection_type } = this.context

    let width
    const vMax = Math.max(screen.width, screen.height)
    const verticalScreen = screen.width < screen.height

    if (vMax <= 800) {
      width = 768
    }
    else if (vMax <= 1200) {
      width = 1080
    }
    else if (vMax <= 1500) {
      width = 1440
    }
    else {
      width = 1920
    }

    if (!coverKey) {
      this.setCover()
      return
    }

    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    if (verticalScreen) {
      this.setCover(
        collection_type === 'new'
        ? cover
        : `https://img.picsize.com.br/thumb/${width}/${width}/${coverPath}`
      )
    }
    else {
      this.setCover(
        collection_type === 'new'
        ? cover
        : `https://img.picsize.com.br/thumb/max/${width}/${coverPath}`
      )
    }
  }

  setCover(url) {
    let img = document.createElement('img')
    img.src = url
    this.setState({ coverUrl: url })

    if (!url) {
      this.setState({ hasCover: false, coverReady: true })
    }

    function _loaded() {
      this.setBlur(img.width, img.height)

      this.setState({ hasCover: true })
      setTimeout(() => this.setState({ coverReady: true }), 1e2)
      window.addEventListener('resize', () => this.setBlur(img.width, img.height))
    }

    if (img.complete) {
      _loaded.bind(this)()
    } else {
      img.addEventListener('load', _loaded.bind(this))
      img.addEventListener('error', () => {
        this.setState({ hasCover: false, coverReady: true })
      })
    }
  }

  setBlur(w, h) {
    const d = Math.max(screen.width - w, screen.height - h)
    if (d <= 0) {
      this.setState({
        blur: ''
      })
    } else if (d < 100) {
      this.setState({
        blur: 'blur-1'
      })
    } else if (d < 200) {
      this.setState({
        blur: 'blur-2'
      })
    } else if (d < 400) {
      this.setState({
        blur: 'blur-3'
      })
    } else if (d < 1200) {
      this.setState({
        blur: 'blur-4'
      })
    } else {
      this.setState({
        blur: 'blur-5'
      })
    }
  }

  downloadAllPictures() {
    DownloadDialogs.download(this.context.hashid)
    this.setState({ menuAnchorElement: null })
  }

  render() {
    const { customization, displayName, collection, home_url, isSelectionActive, select_url, gallery, picturesCount, isClientLoggedIn, avatar } = this.context

    const isShareMode = window.location.search.includes("share=gallery")

    return (
      <div>
        <div className="mobile-navbar">
            {!isShareMode && <IconButton onClick={() => window.location = home_url}>
              <ArrowBackIcon />
            </IconButton>}

            {(
              !!customization && !!customization.logo ?
              <img className="mobile-logo" src={customization.logo} /> : <div className="mobile-logo" />
            )}

            <IconButton onClick={() => {
              const href = `${home_url}?share=gallery` || `${process.env.GALLERY_SHARE_URL}/${this.context.hashid}`
              ShareMenuModal.open(href)
            }}>
              <ShareOutlinedIcon />
            </IconButton>

            <IconButton onClick={event => this.setState({ menuAnchorElement: event.currentTarget })}>
              <MenuIcon />
            </IconButton>

            <Menu
              id="customer-client-menu"
              anchorEl={this.state.menuAnchorElement}
              keepMounted
              open={this.state.menuAnchorElement}
              onClose={() => this.setState({ menuAnchorElement: null })}
              PaperProps={{
                style: {
                  marginTop: 45
                }
              }}
            >
              {
                isSelectionActive && <MenuItem onClick={() => {
                  window.location = (select_url && isClientLoggedIn) ? select_url : `${home_url}/login?callback=select_mode`
                }}>
                <ListItemIcon style={{ minWidth: 35 }}>
                  <TouchAppOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Escolher fotos" />
              </MenuItem>
              }

              {
                gallery.download_all && <MenuItem onClick={() => {
                  window.location = `${home_url}/download`
                }}>
                <ListItemIcon style={{ minWidth: 35 }} >
                  <CloudDownloadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Fazer download das fotos" />
              </MenuItem>
              }

              {isClientLoggedIn && <MenuItem onClick={async () => {
                await API.logoutClientFromGalleryPro()
                window.location.reload()
              }}>
                <ListItemIcon style={{ minWidth: 35 }}>
                  <CancelIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Sair" />
              </MenuItem>}

              {!isClientLoggedIn && (
                <MenuItem
                  style={{ marginRight: 20 }}
                  onClick={() => window.location = `${home_url}/login?callback=preview_mode`}
                >
                  <ListItemIcon style={{ minWidth: 35 }}>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Entrar" />
                </MenuItem>
              )}
            </Menu>
          </div>

        <div className="gallery-info-container">
          { this.state.avatarVisible && <div className="mobile-header">
            <div className="avatar-bar">
              <Container style={{ position: "absolute", top: 0, right: 0, padding: 0, width: "100%", textAlign: "right" }}>
                <IconButton onClick={() => this.setState({ avatarVisible: false })}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Container>
            
              <div className="avatar-info">
                <div className="avatar-container">
                  <Avatar src={avatar} style={{ height: "62px", width: "62px" }} />
                </div>

                <Container style={{ marginLeft: 0 }}>
                  <Typography variant="caption" style={{ lineHeight: "15px", fontSize: 11 }}>
                    FOTOS POR
                  </Typography>
                  <Typography variant="h6" style={{ fontWeight: "bold", lineHeight: "20px", fontSize: 18 }}>
                    {displayName}
                  </Typography>
                </Container>
              </div>

            <Container style={{ position: "absolute", bottom: 0, right: 0, padding: 0, width: "100%", textAlign: "right" }}>
              <Button style={{ color: "#4361FF" }} onClick={() => this.setState({ aboutModalVisible: true })}>
                <Typography variant="caption">
                  Conhecer
                </Typography>
              </Button>
            </Container>
          </div>
        </div>
        }
        <Container style={{ margin: "15px auto 15px 0", width: "100%" }} className="gallery-info">
          <Typography variant="h6" style={{ fontWeight: "bold", fontSize: 22, lineHeight: "20px" }}>{collection.title}</Typography>
          <Typography variant="subtitle1" style={{ fontSize: 14 }}>
            {collection.event_date && `${collection.event_date} |`} {picturesCount === 1 ? `1 FOTO` : `${picturesCount} FOTOS`}
          </Typography>
        </Container>
      </div>
        <Modal
          open={this.state.aboutModalVisible}
          onClose={() => this.setState({ aboutModalVisible: false })}
        >
          <GalleryAboutPage handleClose={() => this.setState({ aboutModalVisible: false })} />
        </Modal>
      </div>
      // <div id='header-wrap' className={`header-wrap ${this.state.coverReady ? '' : 'loading-cover'}`}>
      //   {this.state.hasCover &&
      //     <div className={`header-background ${this.state.blur}`} style={{ background: `url(${this.state.coverUrl})` }}></div>
      //   }

      //   <div className="sel__header" id="sel-header">

      //     <div className='client-data'>
      //       {client && <span>Olá, {client.name}</span>}
      //       {(client && isLoggedIn === true) && (
      //         <span>
      //           (<a onClick={async () => {
      //             await API.postLogout()
      //             if (linkHash) {
      //               window.location = `/s/${linkHash}`
      //               return
      //             }
      //             window.location.reload()
      //           }}>Sair</a>)
      //         </span>
      //       )}
      //     </div>

      //     <div className='collection-title'>
      //       <h2>{collection.title}</h2>
      //       {collection.event_date ? <p>{collection.event_date}</p> : null}
      //     </div>

      //     <div className='call-to-action-wrap'>
      //       <Link
      //         className='call-to-action-btn'
      //         to="page-content"
      //         // offset={-45}
      //         duration={500}
      //         delay={0}
      //         smooth={true}
      //       >Ver Fotos</Link>

      //       {
      //         (selection_active === true) && (
      //           <Link
      //             className='call-to-action-btn'
      //             to=""
      //             duration={500}
      //             delay={0}
      //             smooth={true}
      //             style={{ margin: 20 }}
      //             onClick={() => this.props.push(`/s/${hashid}`)}
      //           >Iniciar Escolha das Fotos</Link>
      //         )
      //       }
      //     </div>

      //     {(
      //       !!customization && !!customization.logo &&
      //       <div className='custom-logo'>
      //         <img src={customization.logo} />
      //       </div>
      //     )}

      //     <div className='arrow-indicator'>
      //       <div className='vert-anim-t'><Icon.AngleDown /></div>
      //     </div>
      //   </div>
      // </div>
    )
  }
}

export default SelectionHeader
