import { saveAs } from 'file-saver'

import API from 'app/modules/API'

let zipTimeout = null

export default {
    download, ready
}

async function download(hashid) {
    const data = await API.prepareGalleryZIP(hashid, { shouldGen: false })

    if (data && data.ready && data.url) {
        // return ready(data)
        const a = document.createElement('a')
        a.href = data.url
        a.dispatchEvent(new MouseEvent('click', {
            'view': window,
            'bubbles': true,
            'cancelable': false
        }))

    }
    else if (data && data.generating) {
        donwloadWaiting()
    }
    else {
        downloadCollection(hashid)
    }
}

function donwloadWaiting() {
    const text = 'O sistema está gerando um arquivo ZIP com todas as fotos da disponíveis da galeria.\
            Na primeira vez que o arquivo for gerado, dependendo do número de fotos,\
            o processo pode demorar alguns minutos. Fechar a página não interfere no processamento do arquivo.'

    swal({
        className: 'swal-justify',
        text,
        closeOnClickOutside: false,
        buttons: {
            cancel: {
                text: 'Fechar',
                value: false,
                visible: true,
                className: '',
                closeModal: true,
            },
            confirm: {
                text: 'Gerar arquivo',
                value: true,
                visible: true,
                className: 'swal-button--loading',
                closeModal: false
            }
        }
    })
    requestZip()
}

function downloadCollection(hashid) {
    swal({
        className: 'swal-justify',
        text: 'Ao clicar em "Gerar arquivo" o sistema criará um link para download de um arquivo ZIP com todas\
            as fotos da disponíveis para seleção. Na primeira vez que o arquivo for gerado, dependendo do\
            número de fotos, o processo pode demorar alguns minutos. Fechar a página não interfere no\
            processamento do arquivo.',
        closeOnClickOutside: false,
        buttons: {
            cancel: {
                text: 'Fechar',
                value: false,
                visible: true,
                className: '',
                closeModal: true,
            },
            confirm: {
                text: 'Gerar arquivo',
                value: true,
                visible: true,
                className: '',
                closeModal: false
            }
        }
    })
        .then((willGen) => {
            if (!willGen) return false
            return requestZip(hashid)
        })
}

async function requestZip(hashid) {
    const data = await API.prepareGalleryZIP(hashid, { shouldGen: true })
    if (!data) {
        swal.stopLoading()
        swal.close()
        return
    }
    if (data.ready) {
        const content = document.createElement('a')
        content.href = data.url
        const contentButton = document.createElement('button')
        contentButton.classList.add('swal-button', 'swal-button--confirm')
        contentButton.style.backgroundColor = '#a3dd82'
        contentButton.style.margin = '35px 0px'
        const t = document.createTextNode('Baixar arquivo')
        contentButton.appendChild(t)
        content.appendChild(contentButton)
        swal({
            icon: 'success',
            title: 'Arquivo pronto!',
            content: content,
            buttons: {
                cancel: {
                    text: 'Fechar',
                    value: false,
                    visible: true,
                    className: '',
                    closeModal: true,
                }
            }
        })
    } else {
        if (zipTimeout) clearTimeout(zipTimeout)
        zipTimeout = setTimeout(() => requestZip(hashid), 5000)
    }
}

async function ready(data) {
    const { blob, fileName } = data
    const b = blob.slice(0, blob.size, 'application/octet-stream')
    const url = URL.createObjectURL(b)
    const contentButton = document.createElement('button')
    contentButton.classList.add('swal-button', 'swal-button--confirm')
    contentButton.style.backgroundColor = '#a3dd82'
    contentButton.style.margin = '35px 0px'
    const t = document.createTextNode('Baixar arquivo')
    contentButton.appendChild(t)
    const content = document.createElement('a')
    content.appendChild(contentButton)
    content.href = url
    if (fileName) {
        content.download = fileName
    }
    swal({
        icon: 'success',
        title: 'Arquivo pronto!',
        content: content,
        buttons: {
            cancel: {
                text: 'Fechar',
                value: false,
                visible: true,
                className: '',
                closeModal: true,
            }
        }
    })


    // const reader = new FileReader();
    // reader.readAsDataURL(b);
    // reader.onloadend = function () {
    //     const base64data = reader.result
    //     // console.log(base64data)
    //     // content.href = data.url
    //     // content.href = 'data:attachment/file;' + base64data.split(';').pop()
    //     content.href = 'data:attachment/file;base64,' + base64data.substr(base64data.indexOf(',') + 1)
    //     // content.href = url
    //     // console.log( data)
    //     // const b = data.blob
    //     // content.href = url
    //     // console.log(content.href)
    //     // return
    //     if (fileName) {
    //         content.download = fileName
    //     }
    //     // content.addEventListener('click', () => window.open(url))
    //     // content.addEventListener('click', () => saveAs(blob, fileName))
    //     // console.log(base64data);
    // }
}
