import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import SelectionContext from '../../context'

import './style.scss'

class PreviewInfo extends Component {
  static contextType = SelectionContext
  constructor(props, context) {
    super(props, context)
  }

  render() {
    const { contracted_pictures } = this.context.selection
    return (
      <div className="sel__picture-preview__info">
        <span>{' Selecionadas: '}</span>
        <span>{this.context.getSelectedCount()}</span>
        {!!contracted_pictures && <span>{` (de até ${contracted_pictures})`}</span>}
      </div>
    )
  }
}

export default PreviewInfo
