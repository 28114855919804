import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CheckoutOverview from './Overview'
import CheckoutPictureList from './PictureList'

import SelectionContext from '../context'

import './style.scss'

class SelectionCheckout extends Component {
  static contextType = SelectionContext
  static propTypes = {
    picturesCount: PropTypes.any
  }

  constructor(props, context) {
    super(props, context)
  }

  async componentDidMount () {
    if (!this.context.visiblePictures || !this.context.visiblePictures.length) return
    this.context.updateVisiblePicturesArray()
    window.scrollTo(0, 1)
  }

  render () {
    if (!this.props.picturesCount) return null
    return (
      <div className="sel__checkout">
        <CheckoutOverview picturesCount={this.props.picturesCount} />
        <CheckoutPictureList />
      </div>
    )
  }
}

export default SelectionCheckout
