import ReactDOM from 'react-dom'
import {Router, browserHistory} from 'react-router'
import ReactGA from 'react-ga'
import routes from './routes'
// import './modernizr-custom'

import 'normalize.css'
import 'fonts/fonts.css'

ReactGA.initialize('UA-81145851-5')

window.appHistory = browserHistory

browserHistory.listen((location) =>  {
  ReactGA.pageview(location.pathname)
})

ReactDOM.render(
  <Router history={browserHistory}>{routes}</Router>,
  document.getElementById('app')
)
