// eslint-disable-next-line max-statements
export function calc(imgs, aW) {
  let desiredHeight = 330
  if (screen.width < 600) desiredHeight = 150
  if (screen.width > 601 && screen.width < 900) desiredHeight = 256
  if (screen.width > 901 && screen.width < 1280) desiredHeight = 300
  if (screen.width > 1280) desiredHeight = 330
  aW -= 8

  let row = []
  let use = 0
  let id = 0, y = 0
  for (const i of imgs) {
    i.width = (i.originalWidth * desiredHeight) / i.originalHeight
    i.height = desiredHeight
    
    row[id] = i
    use += i.width

    if (use >= aW) {
      let exceded = aW - use
      let x
      for (x = y; x < row.length; x++) {
        let percent = row[x].width / use
        let decrease = exceded * percent
        let width = (row[x].width + decrease)
        let height = (row[x].height * width) / row[x].width
        row[x].width = width - 8
        row[x].height = height
      }
      y = x
      use = 0
    }

    id++
  }

}
