import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import API from 'app/modules/API'
import swal from 'sweetalert'

import Icon from 'app/widgets/Icon'
import Button from 'app/widgets/Button'
import DownloadPopover from './download-popover'

import SelectionContext from '../context'

import './style.scss'

class SelectionStatusBar extends Component {
  static contextType = SelectionContext
  zipTimeout = null

  constructor(props, context) {
    super(props, context)
    this.state = {
      style: {}
    }

    this.onScroll = this.onScroll.bind(this)
    this.download = this.download.bind(this)
    this.requestZip = this.requestZip.bind(this)
    this.barRef = React.createRef()
  }
  async componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  onScroll() {
    if (this.props.isSummaryPage || window.scrollY > (document.getElementById('header-wrap').clientHeight)) {
      if (this.state.style.position) return
      this.setState({
        style: {
          top: 0,
          position: 'fixed',
          marginTop: '0'
        }
      })
    } else {
      if (!this.state.style.position) return
      this.setState({
        style: new Object(null)
      })
    }
  }

  download() {
    let el = document.getElementById('download-btn')
    if (!el) return

    DownloadPopover.show(el, this.context, _onAction.bind(this))

    async function _onAction(k) {
      const data = await API.postSelectionZIP(this.context.hashid, { filter: k, shouldGen: false })

      if (data && data.ready && data.url) {
        const a = document.createElement('a')
        a.href = data.url
        a.click()
      }
      else if (data && data.generating) {
        this.donwloadWaiting()
      }
      else {
        this.downloadCollection(k)
      }
    }
  }

  donwloadWaiting(filter) {
    let text;
    if (filter) text = 'O sistema está gerando de um arquivo ZIP com as fotos selecionadas. Na primeira vez que o arquivo for gerado, dependendo do número de fotos, o processo pode demorar alguns minutos. Fechar a página não interfere no processamento do arquivo.'
    else text = 'O sistema está gerando um arquivo ZIP com todas as fotos da disponíveis para seleção. Na primeira vez que o arquivo for gerado, dependendo do número de fotos, o processo pode demorar alguns minutos. Fechar a página não interfere no processamento do arquivo.'
    swal({
      className: 'swal-justify',
      text,
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Gerar arquivo',
          value: true,
          visible: true,
          className: 'swal-button--loading',
          closeModal: false
        }
      }
    })
    this.requestZip(filter)
  }

  downloadCollection(filter) {
    const { isGenericLink } = this.context

    if (isGenericLink) {
      return this.props.openLoginModal()
    }

    swal({
      className: 'swal-justify',
      text: 'Ao clicar em "Gerar arquivo" o sistema criará um link para download de um arquivo ZIP com todas as fotos da disponíveis para seleção. Na primeira vez que o arquivo for gerado, dependendo do número de fotos, o processo pode demorar alguns minutos. Fechar a página não interfere no processamento do arquivo.',
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        },
        confirm: {
          text: 'Gerar arquivo',
          value: true,
          visible: true,
          className: '',
          closeModal: false
        }
      }
    })
      .then((willGen) => {
        if (!willGen) return false
        return this.requestZip(filter)
      })
  }

  async requestZip(filter) {
    const data = await API.postSelectionZIP(this.context.hashid, { filter, shouldGen: true })
    if (!data) {
      swal.stopLoading()
      swal.close()
      return
    }
    if (data.ready) {
      const content = document.createElement('a')
      content.href = data.url
      const contentButton = document.createElement('button')
      contentButton.classList.add('swal-button', 'swal-button--confirm')
      contentButton.style.backgroundColor = '#a3dd82'
      contentButton.style.margin = '35px 0px'
      const t = document.createTextNode('Baixar arquivo')
      contentButton.appendChild(t)
      content.appendChild(contentButton)
      swal({
        icon: 'success',
        title: 'Arquivo pronto!',
        content: content,
        buttons: {
          cancel: {
            text: 'Fechar',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          }
        }
      })
    } else {
      if (this.zipTimeout) clearTimeout(this.zipTimeout)
      this.zipTimeout = setTimeout(() => this.requestZip(filter), 5000)
    }
  }

  showInfo() {
    const {collection, selection} = this.context
    const content = document.createElement('div')

    ReactDOM.render([
      <div key='info-content' className='swal-text' style={{padding: '12px', margin: '24px 0 0 0'}}>
        <div className='sel-desc'>
          {!!collection.description && (
            collection.description.split('\n').map((p, index) => <p key={`sel-desc-p${index}`}>{p}</p>)
          )}
        </div>

        <div className='sel-status'>
          {selection.status === 1 ?
            <div style={{color: '#358340', fontWeight: 'bold'}}>Seleção Concluída!</div>
            :
            (<LimitDate key='limit-date' selection={selection} />)
          }
        </div>

        <ul>
          <li>
            <span>Fotos:</span> {this.props.picturesCount}
          </li>
          {selection.allow_extra && !!selection.extra_cost && (
            <li>
              <span>Valor por foto adicional:</span> R$ {selection.extra_cost.toFixed(2).replace('.', ',')}
            </li>
          )}
        </ul>
      </div>
    ], content)

    swal({
      className: 'swal-selection-info',
      content,
      buttons: {
        cancel: {
          text: 'Fechar',
          value: false,
          visible: true,
          className: '',
          closeModal: true,
        }
      }
    })
  }

  render() {
    const { contracted_pictures, allow_extra } = this.context.selection
    const selectedCount = this.context.getSelectedCount()
    return (
      <div className="sel__status-bar" ref={this.barRef} style={this.state.style}>
        <div className="sel__status-bar__actions">
          {!this.props.isSummaryPage && (
            this.props.isCheckoutPage ? (
              <Button.Info onClick={() => {
                // this.context.goToGallery
                window.history.back()
              }}>
                <Icon.ArrowBack />
                <span>Continuar selecionando</span>
              </Button.Info>
            ) :
              (this.context.showSelectedOnly ? (
                <Button.Success onClick={() => this.context.selectedOnlyToggle()}>
                  Mostrar todas
            </Button.Success>
              ) : !!selectedCount && (
                <Button.Info onClick={() => this.context.selectedOnlyToggle()} isTransparent>
                  Mostrar apenas selecionadas ({selectedCount})
            </Button.Info>
              )
              ))}

          {this.props.isSummaryPage &&
            <button className='status-bar-clear-btn download-btn' id='download-btn' onClick={this.download}>
              <i className='fa fa-download'></i>
            </button>
          }

          <button className='status-bar-clear-btn' onClick={this.showInfo.bind(this)}>
            <i className='fa fa-info-circle'></i>
          </button>
        </div>
        {(<div className="sel__status-bar__status">
          <section onClick={() => {
            if (this.props.isCheckoutPage || this.props.showPreview || this.props.isSummaryPage || !selectedCount) return false
            this.context.selectedOnlyToggle()
          }} style={{ cursor: 'pointer' }}>
            <span>Selecionadas</span>
            <span>
              {this.context.getSelectedCount()}
              {!!contracted_pictures && ` (de até ${contracted_pictures})`}
            </span>
          </section>
        </div>)}
        {!this.props.isSummaryPage && !!allow_extra && (
          <ExtraPicturesStatus
            selection={this.context.selection}
            selectedCount={this.context.getSelectedCount()}
          />
        )}
        {!this.props.isSummaryPage && !!this.context.selection.enabled && (
          this.props.isCheckoutPage ? null : (
            <div className="sel__status-bar__finish">
              <Button.Success disabled={this.context.getSelectedCount() === 0} onClick={this.context.goToCheckout}>
                <Icon.Finish />
                <span>Conferir e finalizar</span>
              </Button.Success>
            </div>
          )
        )}
      </div>
    )
  }
}

function ExtraPicturesStatus({ selection, selectedCount }) {
  const { contracted_pictures, allow_extra, extra_cost } = selection
  if ((selectedCount <= contracted_pictures)) return false

  const extra_count = selectedCount - contracted_pictures
  const total_price = ((extra_cost * extra_count).toFixed(2)).replace('.', ',')

  return (
    <div className="sel__status-bar__status sel__status-bar__extra-status">
      <section>
        <span>Valor Adicional</span>
        <span>R$ {total_price} | {extra_count} fotos</span>
      </section>
    </div>
  )
}

function LimitDate({ selection }) {
  const className = 'sel__header__limit-date'
  
  if (!selection.limit_date) return false
  if (selection.days_left < 0) {
    return (
      <div className={`${className} ${className}--expired`}>
        <strong>Data limite para selecionar:</strong> {selection.limit_date} (<strong>expirado</strong> há {selection.days_left * -1} dias)
      </div>
    )
  }
  return (
    <div className={`${className} ${selection.days_left < 0 ? `${className}--expired` : ''}`}>
      <strong>Data limite para selecionar:</strong> {selection.limit_date} ({selection.days_left} dias restantes)
    </div>
  )
}

export default SelectionStatusBar