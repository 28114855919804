import React, {Component} from 'react'
import PropTypes from 'prop-types'

import InfoTable from 'app/widgets/InfoTable'
import Button from 'app/widgets/Button'
import Alert from 'app/widgets/Alert'

import './style.scss'

function SummaryPictureListItem (props) {
  return (
    <div className="sel__summary__picture-list__item">
      <section>
        <span>{props.picture.title}</span>
      </section>
      <picture>
        <img src={props.picture.thumb} />
      </picture>
      <section>
        <h4>{!!props.pictureComment && 'Comentário'}</h4>
        <blockquote>{props.pictureComment}</blockquote>
      </section>
    </div>
  )
}

SummaryPictureListItem.propTypes = {
  collection: PropTypes.any
}

export default SummaryPictureListItem
