import React, { Component } from 'react'

import ShareMenuModal from '../Dialogs/ShareMenuModal'
import DownloadDialogs from '../Dialogs/DownloadDialogs'
import AboutDialog from '../FullDialogs/About'
import GalleryContext from '../GalleryContext'

import './TabBar.scss'

export default class TabBar extends Component {
    static contextType = GalleryContext
    isAboutDialog = false

    tabs = [
        { key: 'home', icon: 'fa fa-home', text: 'Início', cb: () => this.home() },
        { key: 'download', icon: 'fa fa-download', text: 'Download', cb: () => this.downloadDailog() },
        { key: 'share', icon: 'fa fa-share', text: 'Compartilhar', cb: () => this.shareDialog() },
        { key: 'about', icon: 'fa fa-camera', text: 'O Fotógrafo', cb: () => this.openAboutDialog() },
    ]

    constructor(props, context) {
        super(props, context)
        this.state = {
            status: context.isMobile ? 'mobile-fixed' : '',
        }
        this.filter = this.filter.bind(this)
        this.onScroll = this.onScroll.bind(this)
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll, true)
        this.props.router.listen(this.onRouteChange.bind(this))

        const hashParams = (this.props.router.location.hash || '').split('#').pop().split('/')
        const isAboutDialog = Array.isArray(hashParams) && hashParams[1] === 'about'
        if (isAboutDialog && !this.aboutDialog) {
            this.openAboutDialog(true)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll, true)
    }

    onRouteChange() {
        const hashParams = (this.props.router.location.hash || '').split('#').pop().split('/')
        const isAboutDialog = Array.isArray(hashParams) && hashParams[1] === 'about'
        if (!isAboutDialog && this.aboutDialog) {
            this.aboutDialog.close()
        }
        if (isAboutDialog && !this.aboutDialog) {
            this.openAboutDialog(true)
            this.forceUpdate()
        }
    }

    onSelect(cb) {
        typeof cb === 'function' ? cb() : null
    }

    filter({ key }) {
        switch (key) {
            case 'download':
                return this.context.gallery && this.context.gallery.download_all
            case 'share':
                return this.context.gallery && this.context.gallery.allow_share
        }
        return true
    }

    onScroll() {
        if (this.context.isMobile) {
            return
        }
        if (this.props.isSummaryPage || window.scrollY > (document.getElementById('header-wrap').clientHeight)) {
            this.setState({ status: 'desktop-fixed' })
        } else {
            this.setState({ status: '' })
        }
    }

    home() {
        if (this.aboutDialog) {
            this.aboutDialog.close()
        }
    }

    shareDialog() {
        const href = this.context.gallery.url || `${process.env.GALLERY_SHARE_URL}/${this.context.hashid}`
        ShareMenuModal.open(href)
    }

    downloadDailog() {
        DownloadDialogs.download(this.context.hashid)
    }

    async openAboutDialog(b) {
        if (this.aboutDialog) {
            return
        }

        document.body.classList.add('about-dialog-oppened')
        this.aboutDialog = AboutDialog.open({}, this.context.isMobile)

        const { pathname } = this.props.router.location
        if (!b) {
            this.props.router.push(`${pathname}#/about`)
        }

        await this.aboutDialog.onClose
        this.props.router.push(pathname)
        this.aboutDialog = null
        document.body.classList.remove('about-dialog-oppened')
    }

    render() {
        return (
            <div className={`sf-tab-bar ${this.state.status}`}>
                {this.aboutDialog && this.aboutDialog.component}
                {this.tabs.filter(this.filter).map((i, id) => this.renderMenuItem(i, id))}
            </div>
        )
    }

    renderMenuItem({ text, icon, cb }, index) {
        return (
            <button className='sf-tab-button' key={`sfw-tbi-${index}`} onClick={() => this.onSelect(cb)}>
                <i className={`sf-tab-icon ${icon}`}></i>
                <span>{text}</span>
            </button>
        )
    }

}
