function Comment () {
  return (
    <svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 1222.785 1540"
version={1.0}
height="100%"
width="100%"
preserveAspectRatio="xMidYMid meet"
style={{pointerEvents: 'none', display: 'inline-block'}}>
<path d="M122.785 200h1000q41 0 70.5 29.5t29.5 70.5v600q0 41-29.5 70.5t-70.5 29.5h-596l-304 300v-300h-100q-41 0-70.5-29.5t-29.5-70.5V300q0-41 29.5-70.5t70.5-29.5z" />
</svg>
  )
}

function Approve () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      version={1.0}
      height="20px"
      width="20px"
      preserveAspectRatio="xMidYMid meet"
      >
      <path
        fill="#333"
        d="M24 3l4.7 3.6 5.8-.8 2.2 5.5 5.5 2.2-.8 5.8L45 24l-3.6 4.7.8 5.8-5.5 2.2-2.2 5.5-5.8-.8L24 45l-4.7-3.6-5.8.8-2.2-5.5-5.5-2.2.8-5.8L3 24l3.6-4.7-.8-5.8 5.5-2.2 2.2-5.5 5.8.8" />
      <path
        fill="#fff"
        d="M34.6 14.6L21 28.2l-5.6-5.6-2.8 2.8 8.4 8.4 16.4-16.4" />
    </svg>
  )
}
function Select () {
  return (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 64 64"
  version={1.0}
  height="20px"
  width="20px"
  preserveAspectRatio="xMidYMid meet"
  >
  <path d="M47.2 4C40.47 4 34.682 9.472 32 15.188 29.318 9.473 23.528 4 16.8 4 7.526 4 0 11.527 0 20.802c0 18.865 19.03 23.812 32 42.464 12.26-18.536 32-24.2 32-42.464C64 11.528 56.473 4 47.2 4z" />
  </svg>
  )
}
function Download () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      version={1.0}
      height="20px"
      width="20px"
      preserveAspectRatio="xMidYMid meet"
      >
      <path d="M32 36l16-16H36V4h-8v16H16zm14.545-6.546l-4.484 4.484L58.317 40 32 49.814 5.68 40l16.255-6.062-4.484-4.484L0 36v16l32 12 32-12V36z" />
    </svg>
  )
}
function Unselect () {
  return (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 64 64"
  version={1.0}
  height="20px"
  width="20px"
  preserveAspectRatio="xMidYMid meet"
  >
  <path d="M47.2 4C56.472 4 64 11.527 64 20.802c0 18.263-19.74 23.928-32 42.464C19.03 44.616 0 39.668 0 20.802 0 11.528 7.527 4 16.8 4c3.772 0 7.25 1.72 10.05 4.24L22 16l14 8-8 20 22-24-14-8 3.868-5.803C42.082 4.837 44.568 4 47.198 4z" />
  </svg>
  )
}

function Edit () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      version={1.0}
      height="20px"
      width="20px"
      preserveAspectRatio="xMidYMid meet"
      >
      <path fill="#333" d="M442 150l-39 39-80-80 39-39q6-6 15-6t15 6l50 50q6 6 6 15t-6 15zM64 368l236-236 80 80-236 236H64v-80z" />
    </svg>
  )
}

function ArrowLeft () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 512"
      version={1.0}
      height="20px"
      width="20px"
      preserveAspectRatio="xMidYMid meet"
      >
      <path fill="rgba(255, 255, 255, 0.5)" d="M86 256l167 174c4 4 4 12 0 16l-30 31c-4 4-12 4-16 0L3 264c-2-2-3-5-3-8s1-6 3-8L207 35c4-4 12-3 16 1l30 30c4 4 4 12 0 16L86 256z" />
    </svg>
  )
}

function ArrowRight () {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256 512"
    version={1.0}
    height="20px"
    width="20px"
    preserveAspectRatio="xMidYMid meet"
    >
    <path  fill="rgba(255, 255, 255, 0.5)"  d="M170 256L3 82c-4-4-4-12 0-16l30-30c4-4 12-5 16-1l204 213c2 2 3 5 3 8s-1 6-3 8L49 477c-4 4-12 4-16 0L3 446c-4-4-4-12 0-16z" />
    </svg>
  )
}

function Cancel () {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    version={1.0}
    height="20px"
    width="20px"
    preserveAspectRatio="xMidYMid meet"
    >
    <path
    fill="#D50000"
    d="M24 6C14.1 6 6 14.1 6 24s8.1 18 18 18 18-8.1 18-18S33.9 6 24 6zm0 4c3.1 0 6 1.1 8.4 2.8L12.8 32.4C11.1 30 10 27.1 10 24c0-7.7 6.3-14 14-14zm0 28c-3.1 0-6-1.1-8.4-2.8l19.6-19.6C36.9 18 38 20.9 38 24c0 7.7-6.3 14-14 14z" />
    </svg>
  )
}

function ArrowBack () {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    height="20px"
    width="20px"
    preserveAspectRatio="xMidYMid meet"
    style={{pointerEvents: 'none', display: 'block'}}>
    <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" /></svg>
  )
}

function Back () {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    height="20px"
    width="20px"
    preserveAspectRatio="xMidYMid meet"
    style={{pointerEvents: 'none', display: 'block'}}>
    <path d="M16 7H3.83L9.42 1.4100000000000001L8 0L0 8L8 16L9.41 14.59L3.83 9H16V7Z" /></svg>
  )
}

function Finish () {
  return (
<svg
xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 64 64"
version={1.0}
height="20px"
width="20px"
preserveAspectRatio="xMidYMid meet">
<path d="M54 8L24 38 10 24 0 34l24 24 40-40z" />
</svg>

  )
}
function AngleDown () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48.003731"
      height="24.992456"
      viewBox="0 0 12.700987 6.6125875"
      preserveAspectRatio="xMidYMid meet"
      style={{pointerEvents: 'none', display: 'inline-block'}}>
      <g transform="translate(-98.649509,-145.1937)">
        <path
        style={{fill:'none', stroke:'#fff', strokeWidth:'0.5291667', strokeLinecap: 'round', strokeLinejoin:'round', strokeMiterlimit:4, strokeDasharray: 'none', strokeOpacity:1, paintPrder:'normal'}}
        d="m 111.08324,145.46096 -6.08323,6.08076 -6.083245,-6.08076"
        id="path856" />
      </g>
    </svg>
  )
}
function IphoneMenu() {
  return (
    <svg
      style={{ width: '0.9375em', height: '1.3125em' }}
      className="db relative nt1 f5 c-0e7aee"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 21">
      <path
        fill="currentColor"
        d="M7.105 1.52L4.621 4.016l-.558-.561L7.5 0l3.437 3.456-.558.56L7.895 1.52v11.94h-.79V1.52zm2.369 4.399H15V21H0V5.919h5.526v.794H.79v13.493h13.422V6.713H9.474v-.794z" />
    </svg>
  )
}

export default {
  ArrowLeft,
  ArrowRight,
  ArrowBack,
  Back,
  AngleDown,
  Comment,
  Finish,
  Download,
  Approve,
  Cancel,
  Select,
  Unselect,
  Edit,
  IphoneMenu
}
