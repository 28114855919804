import React, {Component} from 'react'
import PropTypes from 'prop-types'

import InfoTable from 'app/widgets/InfoTable'
import Button from 'app/widgets/Button'
import Alert from 'app/widgets/Alert'

import './style.scss'

function PictureListItem (props) {
  return (
    <div className="sel__checkout__picture-list__item" tabIndex="0">
      <header>
        <Button onClick={props.maximize}> Tela cheia </Button>
        <Button.Info onClick={props.editComment}> {props.pictureComment ? 'Editar comentário' : 'Adicionar comentário'} </Button.Info>
        <Button.Danger onClick={props.unselect}> Desmarcar </Button.Danger>
      </header>
      <picture  onClick={props.maximize} style={{backgroundImage: `url(${props.picture.mini_thumb})`}}>
        <img src={props.picture.thumb} />
      </picture>
      <CommentSection onClick={props.editComment} value={props.pictureComment} />
    </div>
  )
}

function CommentSection (props) {
  if (!props.value || !props.value.length) return false
  return (
    <section tabIndex="0" onClick={props.onClick}>
      <h4>Comentário</h4>
      <blockquote>{props.value}</blockquote>
    </section>
  )
}

PictureListItem.propTypes = {
  collection: PropTypes.any
}

export default PictureListItem
