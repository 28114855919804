import ReactDOM from 'react-dom'
import React, {Component} from 'react'
import PropTypes, { bool } from 'prop-types'
import swal from 'sweetalert'
import {Tooltip} from 'antd'
import CustomDownloadName from '~/widgets/CustomDownloadName'
import Utils from '~/modules/Utils'

import Icon from 'app/widgets/Icon'
import Button from 'app/widgets/Button'

import './style.scss'

class GalleryItem extends Component {

  constructor (state, context) {
    super(state, context)
    // this.setState({src: this.props.picture.mini_thumb})
    this.state = {
      isMobile: Utils.isMobileOrTablet()
    }
    this.imgRef = React.createRef()
  }

  shouldComponentUpdate () {
    return this.imgRef.current.attributes['data-is-visible'].value === '1'
  }

  componentDidMount () {
    this.props.observer.observe(this.imgRef.current)
  }

  componentWillUnmount () {
    this.props.observer.unobserve(this.imgRef.current)
  }

  async download(recheck) {
    if (!recheck && this.props.picture.loading) {
      return
    }
    this.props.picture.loading = true
    this.forceUpdate()
    const res = await this.props.download(this.props.picture)
    if (res) {
      this.props.picture.loading = false
      this.forceUpdate()
    }
    else {
      setTimeout(() => this.download(true), 5e3)
    }
  }

  render () {
    const {isSelected, isCommented, picture, collectionType} = this.props
    let baseClassName
    //if (collectionType === 'new' && this.state.isMobile) {
    if (this.state.isMobile) {
      baseClassName = 'sel__gallery__item__mobile'
    } else {
      baseClassName = 'sel__gallery__item'
    }
    /*if (collectionType === 'new') {
      baseClassName = 'sel__gallery__item'
    } else {
      baseClassName = 'sel__gallery__item--old'
    }*/
    let className = baseClassName
    if (isCommented) {
      className += ` ${className}--commented`
    } else if (isSelected) {
      className += ` ${className}--selected`
    }
    return (
      <div className={className} id={this.props.id}>
        {isSelected && !isCommented && (
          <section className={`${baseClassName}__icon ${baseClassName}__icon--selected`}>
            <Icon.Select />
          </section>
        )}
        {isCommented && (
          <section className={`${baseClassName}__icon ${baseClassName}__icon--commented`}>
            <Icon.Comment />
          </section>
        )}
        <CustomDownloadName name={this.props.picture.title}>
          <picture onClick={this.props.openPicturePreview}>
            <img
              data-thumb-src={this.props.picture.thumb}
              data-mini-src={this.props.picture.mini_thumb}
              data-is-visible={0}
              ref={this.imgRef}
            />
          </picture>
        </CustomDownloadName>
        {picture.suggested && <div className='suggested-flag'>
          <Tooltip placement='bottom' title='Foto sugerida pelo fotógrafo'>
            <i className='fa fa-star'></i>
          </Tooltip>
        </div>}
        {this.props.allowCopy &&
          <div className={`download-btn-wrap ${picture.suggested ? 'push-bottom' : ''} ${picture.loading?'loading':''}`}>
            <Tooltip placement='bottomLeft' title={`${picture.loading?'Preparando':'Faça'} download do arquivo`}>
              <button className='download-btn' onClick={e => this.download(e)}>
                <i className={`fa ${picture.loading ? 'fa-spinner fa-pulse' : 'fa-download'}`}></i>
              </button>
            </Tooltip>
          </div>
        }
        {this.props.selectionEnabled && !this.state.isMobile && <div className='action-btns'>
          {isSelected ? [
            <Button.Warning isNeutral key={`${this.props.picture.id}-unselect`} onClick={() => this.props.setPictureState(false)}>
              <Icon.Unselect /> <span>Desmarcar</span>
            </Button.Warning>,
            <Button.Info isNeutral key={`${this.props.picture.id}-comment`} onClick={() => this.props.toggleCommentBox()}>
              <Icon.Comment /> <span>Comentar</span>
            </Button.Info>
          ] : (
            <Button.Success isNeutral onClick={() => this.props.setPictureState(true)}>
              <Icon.Select /> <span>Selecionar</span>
            </Button.Success>
          )}
        </div>}
    </div>
    )
  }
}

GalleryItem.propTypes = {
  picture: PropTypes.any,
  download: PropTypes.func,
  allowCopy: PropTypes.bool
}


export default GalleryItem
