import React, { Component } from 'react'
import { Link, scroller } from "react-scroll"
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined"
import MenuIcon from "@material-ui/icons/Menu"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import HomeIcon from "@material-ui/icons/Home"
import TouchAppOutlinedIcon from "@material-ui/icons/TouchAppOutlined"
import SaveAltIcon from "@material-ui/icons/SaveAlt"
import MobileScreenShareOutlinedIcon from "@material-ui/icons/MobileScreenShareOutlined"

import API from '~/modules/API'
import Icon from 'app/widgets/Icon'
import ShareMenuModal from "~/pages/Gallery/Dialogs/ShareMenuModal"

import SelectionContext from '../context'

import './style.scss'

class SelectionHeader extends Component {
  static contextType = SelectionContext
  shouldUnload = false
  headerWrap;

  constructor(props, context) {
    super(props, context)
    this.state = {
      coverUrl: '',
      hasCover: false,
      coverReady: false,
      blur: ''
    }
    this.unloadCover = this.unloadCover.bind(this)
    this.loadCover = this.loadCover.bind(this)
    this.onScroll = this.onScroll.bind(this)
  }

  componentDidMount() {
    this.headerWrap = document.getElementById('header-wrap')
    window.addEventListener('scroll', this.onScroll, true)
    this.loadCover()
    if (this.props.isCheckoutPage) {
      setTimeout(() => scroller.scrollTo('page-content', { offset: -45 }), 1e2)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, true)
  }

  onScroll() {
    if (window.scrollY > this.headerWrap.clientHeight) {
      if (!this.shouldUnload) {
        this.unloadCover();
        this.shouldUnload = true
      }
    } 
    else  {
      if (this.shouldUnload) {
        this.loadCover();
        this.shouldUnload = false
      }
    }
  }

  unloadCover() {
    const { coverKey, cover } = this.context

    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    this.setCover(`https://img.picsize.com.br/thumb/max/32/${coverPath}`)    
  }

  loadCover() {
    const { coverKey, cover, collection_type } = this.context
    
    let width
    
    if (screen.width <= 800) {
      width = 768
    }
    else if (screen.width <= 1200) {
      width = 1080
    }
    else if (screen.width <= 1500) {
      width = 1440
    }
    else {
      width = 1920
    }
    
    const coverPath = typeof coverKey === 'string' ? coverKey.split('customer/')[1] : cover.split('customer/')[1]
    this.setCover(
      collection_type === 'new'
      ? cover
      : `https://img.picsize.com.br/thumb/max/${width}/${coverPath}`
    )
  }

  setCover(url) {
    let img = document.createElement('img')
    img.src = url
    this.setState({ coverUrl: url })

    function _loaded() {
      this.setBlur(img.width, img.height)

      this.setState({ hasCover: true })
      setTimeout(() => this.setState({ coverReady: true }), 1e2)
      window.addEventListener('resize', () => this.setBlur(img.width, img.height))
    }

    if (img.complete) {
      _loaded.bind(this)()
    } else {
      img.addEventListener('load', _loaded.bind(this))
      img.addEventListener('error', () => {
        this.setState({ hasCover: false, coverReady: true })
      })
    }
  }

  setBlur(w, h) {
    const d = Math.max(screen.width - w, screen.height - h)
    if (d <= 0) {
      this.setState({
        blur: ''
      })
    } else if (d < 100) {
      this.setState({
        blur: 'blur-1'
      })
    } else if (d < 200) {
      this.setState({
        blur: 'blur-2'
      })
    } else if (d < 400) {
      this.setState({
        blur: 'blur-3'
      })
    } else if (d < 1200) {
      this.setState({
        blur: 'blur-4'
      })
    } else {
      this.setState({
        blur: 'blur-5'
      })
    }
  }

  render() {
    const { customization, collection, isLoggedIn, client, linkHash, collection_type, home_url, isClientLoggedIn } = this.context

    if (!client || !isClientLoggedIn) {
      window.location = `${home_url}/login?callback=select_mode`
    }

    return (
      <div className="mobile-navbar">
        <IconButton onClick={() => window.location = home_url}>
          <ArrowBackIcon />
        </IconButton>

        {(
          !!customization && !!customization.logo ?
          <img className="mobile-logo" src={customization.logo} /> : <div className="mobile-logo" />
        )}

        {/* {client && <IconButton onClick={event => this.setState({ menuAnchorElement: event.currentTarget })}>
          <MenuIcon />
        </IconButton>} */}
        <Menu
					id="customer-client-menu"
					anchorEl={this.state.menuAnchorElement}
					keepMounted
					open={this.state.menuAnchorElement}
					onClose={() => this.setState({ menuAnchorElement: null })}
					PaperProps={{
						style: {
							marginTop: 45
						}
					}}
				>
          <MenuItem onClick={async () => {
            await API.logoutClientFromGalleryPro()
            await API.postLogout()
            return window.location = home_url
          }}>
            <ListItemIcon style={{ minWidth: 35 }}>
							<ExitToAppIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText primary="Sair" />
          </MenuItem>
				</Menu>
      </div>
    )

    // if (collection_type === 'new') {
    //   return (
    //     <div id='header-wrap' className='header-wrap no-cover-header'>
    //       <div className="sel__header no-cover" id="sel-header">
  
    //         {(
    //           !!customization && !!customization.logo &&
    //           <div className='custom-logo'>
    //             <img src={customization.logo} />
    //           </div>
    //         )}

    //         <div className='client-data'>
    //           {client && <span>Olá, {client.name}</span>}
    //           {(client && isLoggedIn === true) && (
    //             <span>
    //               {" "}(<a onClick={async () => {
    //                 await API.postLogout()
    //                 if (linkHash) {
    //                   window.location = `/${linkHash}/select`
    //                   return
    //                 }
    //                 window.location.reload()
    //               }}>Sair</a>)
    //             </span>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //   )
    // } else {
    //   return (
    //     <div id='header-wrap' className={`header-wrap ${this.state.coverReady ? '' : 'loading-cover'}`}>
    //       {this.state.hasCover &&
    //         <div className={`header-background ${this.state.blur}`} style={{ background: `url(${this.state.coverUrl})` }}></div>
    //       }
  
    //       <div className="sel__header" id="sel-header">
  
    //         <div className='client-data'>
    //           {client && <span>Olá, {client.name}</span>}
    //           {(client && isLoggedIn === true) && (
    //             <span>
    //               (<a onClick={async () => {
    //                 await API.postLogout()
    //                 if (linkHash) {
    //                   window.location = `/${linkHash}/select`
    //                   return
    //                 }
    //                 window.location.reload()
    //               }}>Sair</a>)
    //             </span>
    //           )}
    //         </div>
  
    //         <div className='collection-title'>
    //           <h2>{collection.title}</h2>
    //         </div>
  
    //         <div className='call-to-action-wrap'>
    //           <Link
    //             className='call-to-action-btn'
    //             to="page-content"
    //             offset={-45}
    //             duration={500}
    //             delay={0}
    //             smooth={true}
    //           >{this.props.isCheckoutPage ? 'Conferir Fotos Selecionadas' : 'Iniciar Escolha das Fotos'}</Link>
    //         </div>
  
    //         {(
    //           !!customization && !!customization.logo &&
    //           <div className='custom-logo'>
    //             <img src={customization.logo} />
    //           </div>
    //         )}
  
    //         <div className='arrow-indicator'>
    //           <div className='vert-anim-t'><Icon.AngleDown /></div>
    //         </div>
    //       </div>
    //     </div>
    //   )
    // }
  }
}

export default SelectionHeader
