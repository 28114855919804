import React, { Component } from "react";

import API from '~/modules/API'
import SelectionPicturePreview from './PicturePreview'
import SelectionGallery from './Gallery'

export default class SinglePicturePage extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      hashid: props.routeParams.hashid,
      picid: props.routeParams.picid,
      picture: null,
    }

    this.onContextMenu = this.onContextMenu.bind(this)
  }

  componentDidMount() {
    window.addEventListener('contextmenu', this.onContextMenu)
    this.getData()
  }

  componentWillUnmount() {
    window.removeEventListener('contextmenu', this.onContextMenu)
  }

  onContextMenu(e) {
    if (this.state && this.state.picture && this.state.picture.single_download) {
      return
    }
    e.preventDefault()
  }

  async getData() {
    const picture = await API.getGalleryPicture(this.state.hashid)
    console.log(this.state.hashid, this.state.picid)

    if (!picture || !picture.id) {
      return
    }

    document.title = `${picture.title}`

    this.setState({ picture })
  }

  getPictureData() {
    return this.state.picture
  }

  render() {
    return (
      <div className='sel gallery-picture-page'>
        {this.state.picture && 
          <div className='picture-viewer'>
            <img src={this.state.picture.image} draggable='false' />
          </div>
        }

        <footer>
          <p>
            Copyright &copy; {(new Date).getFullYear()} | Todos os direitos reservados
            </p>
          <p>
            <span>by </span>
            <a href="https://www.picsize.com.br/" target="_blank">
              PICSIZE
            </a>
          </p>

        </footer>
      </div>
    )
  }
}
