import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Hammer from 'hammerjs'

import SelectionContext from '../context'
import PreviewActions from './Actions'
import PreviewTools from './Tools'
import PreviewImage from './Image'
import PreviewInfo from './Info'
import CommentArea from './CommentArea'

import './style.scss'

const MIN_ZOOM = 80
const MAX_ZOOM = 340
const ZOOM_STEP = 20
const DEFAULT_ZOOM = 80

class SelectionPicturePreview extends Component {
  static contextType = SelectionContext

  constructor(props, context) {
    super(props, context)

    this.state = {
      currentZoom: DEFAULT_ZOOM,
      slideSide: 'right',
      canSwap: true
    }

    // Pinch zoom https://stackoverflow.com/a/2919434/3058802
    this.setZoom = this.setZoom.bind(this)
    this.setZoomFocus = this.setZoomFocus.bind(this)
    this.zoomTo = this.zoomTo.bind(this)
    this.navigate = this.navigate.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)

    this.imgRef = React.createRef()
    this.imgContainerRef = React.createRef()
  }

  onKeyDown(event) {
    if (this.context.showCommentBox) {
      if (event.key === 'Escape') return this.context.toggleCommentBox()
      return
    }
    if (event.key === 'Escape') {
      this.context.previewToggle()
    } else if (event.key === 'ArrowRight') {
      this.navigate(1)
    } else if (event.key === 'ArrowLeft') {
      this.navigate(-1)
    } else if (event.key === '+') {
      this.setZoom(ZOOM_STEP)
    } else if (event.key === '-') {
      this.setZoom(-1 * ZOOM_STEP)
    }
  }

  next = () => {
    if (this.imgRef.current.pz.canDrag()) return
    this.navigate(1)
  }

  prev = () => {
    if (this.imgRef.current.pz.canDrag()) return
    this.navigate(-1)
  }

  canSwap = () => {
    return !!this.imgRef.current && !this.imgRef.current.pz.canDrag()
  }

  onZoomUpdate = () => {
    this.setState({
      canSwap: !!this.imgRef.current && !this.imgRef.current.pz.canDrag()
    })
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false)
    this.hammer = new Hammer.Manager(this.imgContainerRef.current, {
      recognizers: [
        [Hammer.Swipe, { threshold: 60 }]
      ]
    })
    this.hammer.on('swipeleft', this.next)
    this.hammer.on('swiperight', this.prev)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false)
    this.hammer.off('swipeleft', this.next)
    this.hammer.off('swiperight', this.prev)
  }

  setZoom(diff = 0) {
    let newValue = this.state.currentZoom
    if (diff > 0) newValue = Math.min(newValue + diff, MAX_ZOOM)
    else newValue = Math.max(newValue + diff, MIN_ZOOM)

    if (newValue === DEFAULT_ZOOM) {
      this.imgRef.current.style.transformOrigin = ''
    }
    this.setState({ currentZoom: newValue }, () => {
      this.imgRef.current.style.transform = `scale(${this.state.currentZoom / 100})`
    })
  }

  zoomTo(scale) {
    let newValue = scale * 100
    newValue = Math.min(newValue, MAX_ZOOM)
    newValue = Math.max(newValue, MIN_ZOOM)

    this.setState({ currentZoom: newValue }, () => {
      this.imgRef.current.style.transform = `scale(${this.state.currentZoom / 100})`
    })
  }

  getNextSrc() {
    const index = this.context.visiblePictures.indexOf(this.context.highlightedPictureId.toString())
    const toIndex = index + 1
    if (toIndex < this.context.visiblePictures.length) {
      return this.context.getPictureData(this.context.visiblePictures[toIndex]).image
    }
    return undefined
  }

  getPrevSrc() {
    const index = this.context.visiblePictures.indexOf(this.context.highlightedPictureId.toString())
    const toIndex = index - 1
    if (toIndex < this.context.visiblePictures.length) {
      return this.context.getPictureData(this.context.visiblePictures[toIndex]).image
    }
    return undefined
  }

  navigate(to) {
    const index = this.context.visiblePictures.indexOf(this.context.highlightedPictureId.toString())
    const toIndex = (+index) + to
    if ((toIndex > -1) && (toIndex < this.context.visiblePictures.length)) {
      this.context.previewToggle(this.context.visiblePictures[toIndex])
      return
    }
    // if (to > 0) {
    //   this.setState({
    //     currentPicture: '//img.picsize.com.br/uploads/customer/333/sel-picture/12729/3322881/7o5a2778.jpeg'
    //   })
    // } else {
    //   this.setState({
    //     currentPicture: '//img.picsize.com.br/uploads/customer/333/sel-picture/12729/3322854/7o5a2752.jpeg'
    //   })
    // }
  }

  setZoomFocus(e) {
    // return true
    // if (this.state.currentZoom === DEFAULT_ZOOM) {
    //   this.imgRef.current.style.transformOrigin = ''
    //   return
    // }
    // // reference: https://codepen.io/danwilson/pen/qXPdbw
    // const box = this.imgRef.current.parentElement.getBoundingClientRect()
    // const side = box.right - box.left
    // const x = e.clientX || e.changedTouches[0].clientX
    // const y = e.clientY || e.changedTouches[0].clientY
    // const ex = (x - box.left) / side
    // const ey = (y - box.top) / side
    // this.setState({zooming: true}, () => {
    //   this.imgRef.current.style.transformOrigin = Math.round(ex * 100) + '% ' + Math.round(ey * 100) + '%'
    // })
  }

  render() {
    if (!this.context.highlightedPictureId) return false
    if (!this.context.getPictureData(this.context.highlightedPictureId)) return false
    const prevSrc = this.getPrevSrc()
    const nextSrc = this.getNextSrc()

    return (
      <div className="sel__picture-preview">
        <PreviewActions />
        <PreviewTools
          key={`preview-tools-${this.context.highlightedPictureId}`}
          currentZoom={`${parseInt(this.state.currentZoom)}%`}
          zoomIn={() => this.setZoom(ZOOM_STEP)}
          zoomOut={() => this.setZoom(-1 * ZOOM_STEP)}
          zoomTo={(scale) => this.zoomTo(scale)}
          canSwap={this.state.canSwap}
          prev={this.prev}
          next={this.next}
          picturesCount={this.props.picturesCount}
        />
        <PreviewImage
          picId={this.context.highlightedPictureId}
          prevSrc={prevSrc}
          nextSrc={nextSrc}
          picture={this.context.getPictureData(this.context.highlightedPictureId)}
          imgRef={this.imgRef}
          containerRef={this.imgContainerRef}
          onZoomUpdate={this.onZoomUpdate}
          slideSide={this.state.slideSide}
          onClick={this.setZoomFocus}
        />
        <PreviewInfo />
        {this.context.showCommentBox && <CommentArea />}
      </div>
    )
  }
}

SelectionPicturePreview.propTypes = {
  collection: PropTypes.any
}

export default SelectionPicturePreview
